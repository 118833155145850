export const url = "https://www.jingqun.top/todo/api";
//export const url = "http://localhost:5000/todo/api";

export const setHeaders = () => {
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  };

  return headers;
};